.App {
  /* text-align: center; */
  font-family: Arial, Sans-serif;
  background-color: #fff;
  color: #444;
  margin: 0px auto;
  max-width: 981px;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.section {
  padding: 20px 19px 20px 22px;
}
.section-twitter {
  padding: 20px 19px 20px 22px;
  background-color: #ff9c00;
}
.pimvandenberg {
  font-family: Georgia;
  padding: 20px 0 0 20px;
  margin-top: 0;
  z-index: 8;
}
h2 {
  padding: 0 0 0 20px;
}
.vimeo {
  font-family: "Black Rose";
}

.twitter-widget-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  background-color: #ff9c00;
  overflow: hidden;
}
.twitter-widget-left-wrapper {
  height: 200px;
  max-width: 510px;
  float: left;
}
.twitter-widget-right-wrapper {
  /* width: 389px; */
  --min-height: 245px;
  /* float: right; */
  position: relative;
  max-width: 598px;
}

.twitter-widget-header {
  color: white;
}

.twitter-widget-tweet {
  border-top: dotted 2px white;
  --margin: 5px 0px 5px 0px;
  padding: 5px 0px 5px 0px;
  color: black;
  list-style: none;
}
.twitter-widget-follow {
  /* border: 1px solid black; */
  border: none;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  min-width: 73px;
  height: 28px;
  font: bold 16px/28px Arial, Sans-serif;
  text-shadow: 1px 1px #666;
  padding: 0 33px 0 18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  /* background-color: #995e00; */
  -webkit-box-shadow: 0 0 4px #995e00;
  -moz-box-shadow: 0 0 4px #995e00;
  box-shadow: 0 0 6px #995e00;
  position: relative;
  margin: 0 0 4px 39px;
  /* margin-bottom: 4px; */
}
.video {
  background-color: #464a45;
  color: #fff;
  padding: 10px 0;
}
.video-section {
  display: flex;
  width: auto;
  overflow: scroll;
  background-color: #464a45;
  color: #fff;
}
.video-title {
  height: 74px;
  padding-left: 20px;
}
.video-container {
  height: auto;
  margin: 0 10px 0 20px;
}
.video-description {
  padding: 10px 0 20px 20px;
}
.video-watch-all {
  padding: 20px 0;
  padding-left: 20px;
}
.video-watch-all a {
  color: #fff;
  /* text-decoration: none; */
}
.assignments-wrapper {
  background-color: #000;
  padding: 20px;
  color: #fff;
}
.assignments-picture {
  float: right;
}
.assignments-pdf {
  padding: 20px 0;
  font-weight: bold;
}
.assignments-pdf a {
  color: #fff;
}
.manifesto-header {
  color: #ff9b00;
}
.manifesto-download {
  padding: 10px 0 20px 0;
}
.manifesto-download a {
  padding: 10px 0 20px 0;
  /* color: #fff; */
}
.manifesto-button {
  background-color: #ff9b00; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* float: right; */
  justify-content: flex-end;
}
.manifesto-text {
  font-family: Georgia, sans-serif;
}
.about-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
}
.about-left {
  padding: 20px;
}
.about-right {
  float: right;
  max-width: 619px;
  font-size: 19px;
  line-height: 25px;
  padding-left: 50px;
}
.about-right h1 {
  color: #ff9b00;
}
.about-specialities {
  padding: 0;
}
.about-speciality {
  list-style: none;
}
.contact {
  background-color: #f60;
  color: #000;
  padding: 20px 19px 20px 22px;
}
.contact-heading {
}
.contact-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.contact-form {
}
.footer {
  background-color: #000;
  height: 95px;
  color: #999;
  display: flex;
  justify-content: space-between;
}
.footer-left {
  padding: 20px;
}
.footer-right {
  padding: 20px;
  float: right;
}

.slideshow-container {
  /* width: 100%; */
  width: 100%;
  /* height: 40vh; */
  height: calc(981 / 652 * 100%);
  /* height: calc(327 / 217 * height); */

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 100px; */
  /* margin-bottom: calc(100px- auto); */
}
.slideshow-container img {
  /* height: 100%; */
}
.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: 20px;
}

.prev {
  left: 20px;
  transform: scale(-1);
}

.slideshow-image {
  /* position: absolute; */
  max-width: 100%;
  width: 100%;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
