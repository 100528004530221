.introduction-header {
  font-style: italic;
  font-family: Georgia;
  font-size: 20px;
  line-height: 26px;
  max-width: 615px;
  color: #444;
}

.introduction-text {
  font-family: Georgia;
  color: #444;
  max-width: 615px;
  font-size: 20px;
  line-height: 26px;
}
